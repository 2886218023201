/* theme-specific colors that work in light and dark modes */

/*
 * muted
 */

.text-muted {
  @apply text-base-600;
}

.dark .text-muted {
  @apply text-base-inverted-500;
}

.bg-muted {
  @apply bg-base-600;
}

.dark .bg-muted {
  @apply bg-base-inverted-500;
}

/*
 * primary
 */

.text-primary {
  @apply text-base-900;
}

.dark .text-primary {
  @apply text-base-inverted-900;
}

.bg-primary {
  @apply bg-base-900;
}

.dark .bg-primary {
  @apply bg-base-inverted-900;
}

/*
 * accent
 */

.text-accent,
.hover\:text-accent:hover {
  @apply text-blue;
}

.dark .text-accent,
.dark .hover\:text-accent:hover {
  @apply text-mint;
}

.bg-accent {
  @apply bg-blue;
}

.dark .bg-accent {
  @apply bg-mint;
}

/*
 * neutral
 */

.text-neutral,
.hover\:text-neutral:hover {
  @apply text-mint;
}

.dark .text-neutral,
.dark .hover\:text-neutral:hover {
  @apply text-mint;
}

.bg-neutral {
  @apply bg-mint;
}

.dark .bg-neutral {
  @apply bg-mint-300;
}

/*
 * background
 */

.text-background,
.hover\:text-background:hover {
  @apply text-white;
}

.dark .text-background,
.dark .hover\:text-background:hover {
  @apply text-base-900;
}

.bg-background {
  @apply bg-white;
}

.dark .bg-background {
  @apply bg-base-900;
}


/*
 * foreground (white for modals + cards)
 */

.text-foreground,
.hover\:text-foreground:hover {
  @apply text-white;
}

.dark .text-foreground,
.dark .hover\:text-foreground:hover {
  @apply text-black;
}

.bg-foreground {
  @apply bg-white;
}

.dark .bg-foreground {
  @apply bg-base-600;
}

