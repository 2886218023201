/* links */

.link {
  @apply text-blue-500;
}

.link:hover {
  @apply underline text-blue-500;
}

.dark .link {
  @apply text-mint-300;
}

.dark .link:hover {
  @apply text-mint-600;
}

.link-plain {
  color: inherit;
}

.link-plain:hover {
  @apply underline text-blue-500;
}

.dark .link-plain:hover {
  @apply underline text-mint-300;
}

/* general text styles */

.text-head {
  @apply text-h1 font-medium text-base;
}

.dark .text-head {
  @apply text-white;
}

.text-subhead {
  @apply text-h3 font-medium text-base;
}

.dark .text-subhead {
  @apply text-white;
}

