/* prose block styles */

.dark .prose,
.dark .prose p,
.dark .prose h1,
.dark .prose h2,
.dark .prose h3 {
  @apply text-base-100;
}

.prose a {
  @apply text-accent;
}

.dark .prose strong {
  @apply text-white;
}

.prose a:hover {
  @apply underline text-accent;
}

.dark .prose a {
  @apply text-accent-dark;
}

.prose hr {
  @apply border-base-300;
}
