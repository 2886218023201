.form-groups {
  @apply space-y-4 md:space-y-8;
}

.form-groups--tight {
  @apply space-y-2 md:space-y-4;
}

.form-group {
  @apply mb-4;
}

.form-group label {
  @apply block text-sm font-medium text-base-800;
}

.dark .form-group label {
  @apply text-white;
}

.form-group .field_with_errors > label,
.dark .form-group .field_with_errors > label {
  @apply text-danger-600;
}

.form-select.text-lg,
.form-input.text-lg {
  @apply sm:text-h4;
}

.dark .form-select,
.dark .form-input {
  @apply text-white bg-base-900 border-2 border-base-500;
}

.dark .field_with_errors .form-select,
.dark .field_with_errors .form-input {
  @apply border-danger-600;
}

.dark .form-select:focus,
.dark .form-input:focus {
  @apply ring ring-mint-300 ring-opacity-50 border-mint-300;
}

.dark .field_with_errors .form-select:focus,
.dark .field_with_errors .form-input:focus {
  @apply ring-danger-600 border-danger-600 ring-offset-black;
}

.form-field {
  @apply mt-1 relative rounded-md shadow-sm;
}

.form-helper {
  @apply mt-2 text-caption font-regular text-base-500
}

.dark .form-helper {
  @apply text-base-300;
}

.form-subhead {
  @apply font-medium text-base-500 mb-5 mt-10 pb-2 border-b border-base-200;
}

.dark .form-subhead {
  @apply text-base-200;
}

/*
 * checkboxes + radios
 */

.form-checkboxes {
  @apply space-y-2;
}

label + .form-checkboxes {
  @apply mt-2;
}

.form-checkboxes > label {
  @apply mb-2;
}

.form-checkboxes--item {
  @apply relative flex items-start;
}

.form-checkboxes--item-check {
  @apply absolute flex items-center h-5;
}

.checkbox {
  @apply relative flex items-center;
}
.form-checkboxes--item-label {
  @apply pl-7 text-sm leading-5;
}

.form-checkboxes--item-label .form-helper {
  @apply mt-0;
}

.form-checkboxes--item-label label {
  @apply block cursor-pointer;
}

.dark .form-checkboxes--item-label label {
  @apply block text-white;
}

/*
 * submit button for native form builder
 */

form input[type=submit] {
  @apply inline-flex items-center text-center border shadow-sm rounded-md space-x-2 transition ease-in-out duration-150 font-regular px-4 py-2 text-body border-transparent text-white shadow bg-blue-500;
}

.dark form input[type=submit] {
  @apply text-white bg-mint-600 ring-offset-base-800;
}

form input[type=submit]:focus {
  @apply outline-none ring-2 ring-offset-2 ring-blue-500;
}

.dark form input[type=submit]:focus {
  @apply ring-mint-600;
}

form input[type=submit]:hover {
  @apply bg-opacity-90;
}

form input[type=submit].block {
  @apply flex justify-center;
}

form input[type=submit]:active:not(:disabled) {
  @apply ring-blue-500 outline-none ring-2 ring-offset-2;
}

.dark form input[type=submit]:active:not(:disabled) {
  @apply ring-mint-600 outline-none ring-2 ring-offset-2 ring-offset-base-800;
}

form input[type=submit]:disabled,
form button[type=submit]:disabled {
  @apply cursor-not-allowed opacity-80 bg-base-400 text-base-600;
}

form input[type=submit].btn--danger {
 @apply inline-flex items-center text-center border shadow-sm rounded-md space-x-2 transition ease-in-out duration-150 font-regular px-4 py-2 text-body border-transparent text-white shadow bg-danger-600;
}

form input[type=submit].btn--danger:focus {
  @apply ring-danger-600;
}

.dark form input[type=submit].btn--danger:focus {
  @apply ring-danger-600;
}

.dark form input[type=submit]..btn--danger:active:not(:disabled) {
  @apply ring-danger-600 outline-none ring-2 ring-offset-2 ring-offset-base-800;
}
