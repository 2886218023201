/* purgecss start ignore */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "../stylesheets/util/font";
/* purgecss end ignore */

@import "tailwindcss/utilities";

/* purgecss start ignore */
@import "../stylesheets/components/dark";
@import "../stylesheets/components/text";
@import "../stylesheets/components/prose";
@import "../stylesheets/components/colors";
@import "../stylesheets/components/form";
@import "../stylesheets/components/page";
@import "../stylesheets/components/datepicker";

.turbo-progress-bar {
    @apply bg-blue-500 h-1;
}

.dark .turbo-progress-bar {
    @apply bg-mint-600;
}

/* purgecss end ignore */

.bg-blue-block {
    background-image: url('images/bg-tangerine.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.ml_pagination {
    background: white;
    cursor: default;
    /* self-clearing method: */
}

.ml_pagination a,
.ml_pagination span,
.ml_pagination em {
    padding: 0.2em 0.8em;
    display: block;
    float: left;
    margin-right: 5px;
    border-radius: 3px;

}

.ml_pagination .disabled {
    color: #999999;
    border: 1px solid #dddddd;
}

.ml_pagination .current {
    font-style: normal;
    font-weight: bold;
    background: #337AB7;
    color: white;
    border: 1px solid #337AB7;
}

.ml_pagination a {
    text-decoration: none;
    color: #337AB7;
    border: 1px solid #337AB7;
}

.ml_pagination a:hover,
.ml_pagination a:focus {
    color: #fff;
    border-color: #337AB7;
    background: #337AB7;
}

.ml_pagination .page_info {
    background: #337AB7;
    color: white;
    padding: 0.4em 0.6em;
    width: 22em;
    margin-bottom: 0.3em;
    text-align: center;
}

.ml_pagination .page_info b {
    color: #337AB7;
    background: #337AB7;
    padding: 0.1em 0.25em;
}

.ml_pagination:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

* html .ml_pagination {
    height: 1%;
}

*:first-child+html .ml_pagination {
    overflow: hidden;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

.bg-blue-img {
    background-image: url('images/bg-tangerine.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.blue-square {
    background-image: url('images/bg-tangerine.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media screen and (max-width: 400px) {
    .blue-square {
        height: 700px;
    }
}

@media (max-width: 401px) and (min-width: 1399px) {
    .blue-square {
        height: 600px;
    }
}

@media screen and (min-width: 1400px) {
    .blue-square {
        height: 500px;
    }
}