/* general dark mode utilities */

html:not(.dark) .light\:hidden {
  display: none;
}

html.dark .dark\:hidden {
  display: none;
}

/* since .dark is also on the html tag, manually apply background since other classes won't apply */
html.dark {
  @apply bg-base-900;
}
